<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("sat.Subscription") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-card shadow="never">
      <h3 class="title text-left">{{ $t("sat.Subscription") }}</h3>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="green-td" style="width:20%">
              {{ $t("plans.currentPlan") }}
            </th>
            <th class="green-td">{{ $t("plans.planDuration") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(subscription, index) in subscriptions" :key="index">
            <td>
              <strong class="text-success">Pro</strong>
            </td>
            <td>
              <span class="timedLimited">
                {{ `${$t("plans.planDuration")}${subscription.current_period_end} (GMT+8)` }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";

export default {
  metaInfo() {
    return {
      title: "Pro - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      subscriptions: []
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.getSubscriptions();
  },

  methods: {
    async getSubscriptions(){
      const res = await SAT.getSubscriptions();
      if(res){
        this.subscriptions = res.subscriptions;
      }
    }
  }
};
</script>

<style scoped>
.el-card {
  background-color: #f2f3f5;
}
.timedLimited {
  color: #00000077;
}
.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}
</style>
